<template>
    <div>
        <i-header :title="title" :showBack="true" @back-event="toBack"></i-header>
        <i-field
            v-model="form.name"
            center
            clearable
            maxLength="50"
            label="联系人姓名"
            placeholder="请输入联系人姓名"
        ></i-field>
        <i-field
            v-model="form.phone"
            center
            clearable
            type="digit"
            maxLength="11"
            label="联系人手机"
            placeholder="请输入联系人手机"
        ></i-field>
        <div class="bottom_btn">
            <i-button type="primary" round @click="save">保 存</i-button>
        </div>

    </div>
</template>

<script>
import {getCookie} from "tiny-cookie";

export default {
    name: "editContacts",
    data() {
        return {
            form: {
                phone: '',
                name: '',
            },
            title: '',
            config: {},
            isEdit: false,
        }
    },
    methods: {
        save() {
            console.log(this.form);
            const {phone, name} = this.form;
            if ([phone, name].every(i => i)) {
                const params = this.isEdit ? {
                    editPhone: this.form.phone,
                    editName: this.form.name,
                    userId: getCookie('userId'),
                    editContactId: this.form.contactId
                } : {
                    addPhone: this.form.phone,
                    addName: this.form.name,
                    userId: getCookie('userId')
                }
                const p = this.isEdit ? this.$service.editContact(params) : this.$service.addNewContact(params);
                p.then(res => {
                    if (res.success) {
                        this.$toast.success('成功');
                        this.toBack();
                    }
                })
            } else {
                this.$toast.fail('请填写相应栏')
            }
        },
        toBack() {
            this.$router.go(-1)
        }
    },
    mounted() {
        console.log(this.$route.query);
        const {title, isEdit, content} = this.$route.query;
        this.title = title;
        this.isEdit = JSON.parse(isEdit);
        if (this.isEdit) {
            console.log(isEdit);
            this.form = JSON.parse(content);
        }
    }
}
</script>

<style scoped lang="less">
.van-field {
    display: block;
}

.bottom_btn {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: .44rem;

    .van-button {
        width: calc(100% - 32px);
        height: 100%;
        padding: 0 0.4rem;
        line-height: 1;
        border-radius: 0.04rem !important;
    }
}
</style>
