var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("i-header", {
        attrs: { title: _vm.title, showBack: true },
        on: { "back-event": _vm.toBack },
      }),
      _c("i-field", {
        attrs: {
          center: "",
          clearable: "",
          maxLength: "50",
          label: "联系人姓名",
          placeholder: "请输入联系人姓名",
        },
        model: {
          value: _vm.form.name,
          callback: function ($$v) {
            _vm.$set(_vm.form, "name", $$v)
          },
          expression: "form.name",
        },
      }),
      _c("i-field", {
        attrs: {
          center: "",
          clearable: "",
          type: "digit",
          maxLength: "11",
          label: "联系人手机",
          placeholder: "请输入联系人手机",
        },
        model: {
          value: _vm.form.phone,
          callback: function ($$v) {
            _vm.$set(_vm.form, "phone", $$v)
          },
          expression: "form.phone",
        },
      }),
      _c(
        "div",
        { staticClass: "bottom_btn" },
        [
          _c(
            "i-button",
            { attrs: { type: "primary", round: "" }, on: { click: _vm.save } },
            [_vm._v("保 存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }